<template>
    <section>
        <modal ref="editarDatos" v-loading="loading" :element-loading-text="`${carga}%`" titulo="Editar datos de la tienda" icon="online-shop" no-aceptar :btns="btns" tamano="modal-lg" @accion="acciones">
            <ValidationObserver ref="validacion">
                <div class="row mx-3 f-15">
                    <div class="col-5 text-center">
                        <p>Logo o foto de la tienda</p>
                        <div class="row justify-center">
                            <slim-cropper ref="cropLogo" :options="slimOptions" class="border" style="height:160px;width:160px;background:#F8F9FF;">
                                <!-- <img :src="formulario.imagen | helper-storage()" alt="" /> -->
                                <div slot="label" class="">
                                    <img src="/img/modales/camera.svg" alt="" />
                                </div>
                            <!-- <input id="imagen" type="file" name="imagen" accept="image/*" /> -->
                            </slim-cropper>
                        </div>
                    </div>
                    <div class="col-7 overflow-auto custom-scroll" style="max-height:40vh;">
                        <div class="my-3">
                            <ValidationProvider v-slot="{errors}" rules="required|max:60" name="nombre dueño">
                                <p class="ml-2">
                                    ¿Como te llamas?
                                </p>
                                <el-input v-model="model.propietario_nombre" maxlength="60" show-word-limit />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="row mx-0 justify-center pt-3">
                            <div class="col-10">
                                <div class="f-15 mx-3 row">
                                    ¿Cuándo Naciste?
                                </div>
                                <el-date-picker v-model="model.propietario_nacimiento" type="date" format="dd - MM - yyyy" value-format="yyyy-MM-dd" class="w-100" :picker-options="pickerOptions" />
                            </div>
                        </div>
                        <div class="row mx-0 justify-center pt-3">
                            <div class="col-10 d-middle-center">
                                <el-radio-group v-model="model.propietario_genero">
                                    <el-radio class="radio-red" :label="2">
                                        Mujer
                                    </el-radio>
                                    <el-radio class="radio-red" :label="1">
                                        Hombre
                                    </el-radio>
                                    <el-radio class="radio-red" :label="3">
                                        Otro
                                    </el-radio>
                                </el-radio-group>
                            </div>
                        </div>
                        <div class="row mx-0 justify-center pt-3">
                            <div class="col-10 position-relative" style="width:337px;">
                                <div class="row mx-3 text-muted2 f-15">
                                    Cúentanos más de ti
                                </div>
                                <ValidationProvider v-slot="{ errors }" rules="required" vid="descripcion" name="descripción">
                                    <el-input
                                    v-model="model.descripcion"
                                    type="textarea"
                                    rows="4"
                                    class="w-100"
                                    placeholder="Describa a qué se dedica su tienda"
                                    maxlength="250"
                                    show-word-limit
                                    />
                                    <span class="text-danger f-11"> {{ errors[0] }} </span>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="my-3">
                            <div class="col-10 f-16 my-2">
                                ¿Dónde estás ubicado?
                            </div>
                            <ValidationProvider v-slot="{errors}" rules="required" name="país">
                                <p class="ml-2">País</p>
                                <el-select v-model="model.idm_pais" class="w-100" :disabled="disabled" filterable @change="consultarEstados">
                                    <el-option
                                    v-for="item in paises"
                                    :key="item.id"
                                    :label="item.nombre"
                                    :value="item.id"
                                    />
                                </el-select>
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="my-3">
                            <ValidationProvider v-slot="{errors}" rules="required" name="estado">
                                <p class="ml-2">Estado</p>
                                <el-select v-model="model.idm_estado" class="w-100" :disabled="disabled" filterable @change="consultarCiudades">
                                    <el-option
                                    v-for="item in estados"
                                    :key="item.id"
                                    :label="item.nombre"
                                    :value="item.id"
                                    />
                                </el-select>
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="my-3">
                            <ValidationProvider v-slot="{errors}" rules="required" name="ciudad">
                                <p class="ml-2">Ciudad</p>
                                <div class="row mx-0">
                                    <el-select v-model="model.idm_ciudad" class="col" :disabled="disabled" filterable>
                                        <el-option
                                        v-for="item in ciudades"
                                        :key="item.id"
                                        :label="item.nombre"
                                        :value="item.id"
                                        />
                                    </el-select>
                                    <i class="icon-pencil f-22 cr-pointer" @click="disabled = !disabled" />
                                </div>
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="my-3">
                            <ValidationProvider v-slot="{errors}" rules="required|max:70" name="dirección">
                                <p class="ml-2">Dirección</p>
                                <el-input v-model="model.direccion" maxlength="70" show-word-limit @change="buscarDireccion" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="my-3">
                            <ValidationProvider v-slot="{errors}" rules="max:40" name="complemento">
                                <p class="ml-2">Complemento (Opcional)</p>
                                <el-input v-model="model.direccion_anexo" maxlength="40" show-word-limit />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="my-3">
                            <mapa ref="mapaPeq" :coordenadas="coordenadas" :buscar="getDireccionCon" @actualizar="actualizarCoordenadas" />
                            <p class="f-13 mt-2 text-gris2 cr-pointer" @click="abrirModalMapa()">
                                Haga click aquí, para seleccionar la ubicación de su tienda
                            </p>
                        </div>
                        <div class="my-3">
                            <ValidationProvider v-slot="{errors}" rules="required|max:30" name="barrio">
                                <p class="ml-2">Barrio </p>
                                <el-input v-model="model.barrio" maxlength="30" show-word-limit />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="row mx-0 pt-3 text-muted2 f-15 justify-center">
                            <div class="col-10 d-middle my-3">
                                <el-checkbox v-model="model.tienda" class="check-red" label="1">
                                    Tengo una tienda
                                </el-checkbox>
                            </div>
                        </div>
                        <template v-if="model.tienda">
                            <div>
                                <div class="row mx-0 justify-center pt-3">
                                    <div class="col-10" style="width:337px;">
                                        <div class="row mx-3 text-muted2 f-15">
                                            Nombre de la tienda
                                        </div>
                                        <ValidationProvider v-slot="{ errors }" rules="required" vid="nombre" name="nombre de la tienda">
                                            <el-input
                                            v-model="model.nombre" class="w-100"
                                            placeholder="¿Cómo se llama tu tienda?"
                                            maxlength="60"
                                            show-word-limit
                                            />
                                            <span class="text-danger f-11">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="my-3">
                                    <ValidationProvider v-slot="{errors}" rules="numeric|max:9" name="NIT">
                                        <p class="ml-2">Nit de la tienda (Opcional)</p>
                                        <el-input v-model="model.nit" maxlength="9" show-word-limit />
                                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                    </ValidationProvider>
                                </div>
                                <div class="my-3">
                                    <ValidationProvider v-slot="{errors}" rules="" name="soporte">
                                        <p class="ml-2">Soporte (Opcional)</p>
                                        <el-upload
                                        ref="adjuntos"
                                        class="upload-demo"
                                        action="#"
                                        :multiple="false"
                                        :limit="1"
                                        :on-change="agregar_archivos"
                                        :auto-upload="false"
                                        :on-remove="handleRemove"
                                        :file-list="fileList"
                                        >
                                            <button type="button" class="btn w-100 br-10 border bg-whitesmoke">
                                                Cargar soporte
                                            </button>
                                        </el-upload>
                                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </ValidationObserver>
            <div class="row mx-0 my-3 justify-content-center text-danger f-12 align-items-center">
                <i class="icon-attention-alt f-18" />
                <p>los cambios  realizados entrarán en revisión, este trámite puede tardar hasta "24 Horas".</p>
            </div>
        </modal>
        <mapa-google-modal
        ref="modalMapaGoogle"
        busqueda
        postal
        :buscar="getDireccionCon"
        :coordenadas-entrada="coordenadas"
        @actualizar="actualizarCoordenadasCiudad"
        />
    </section>
</template>

<script>
import DatosTienda from "../../../../services/configurar/tendero-vip/datos_tienda";
import Localizaciones from "../../../../services/proyectos/localizaciones";
import Aws from '~/services/aws'

export default {
    data(){
        return {
            disabled: true,
            paises: [],
            estados: [],
            ciudades: [],
            slimOptions: {
                ratio: '1:1',
                label:'Subir imagen de perfil',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok'
            },
            pickerOptions: {
                disabledDate(time){
                    return time.getTime() > Date.now()
                },
            },
            model: {
                barrio:null,
                descripcion:null,
                direccion:null,
                direccion_anexo:null,
                estado:null,
                id:null,
                idm_ciudad:null,
                idm_estado:null,
                idm_pais:null,
                latitud:0,
                logo:null,
                logo_mini:null,
                longitud:0,
                nit:null,
                nit_soporte:null,
                nombre:null,
                propietario_nombre:null,
                propietario_genero: null,
                propietario_nacimiento: null,
                tienda: false,
            },
            coordenadas: {
                lat: 0,
                lng: 0,
            },
            loading: false,
            archivo: [],
            fileList: [],
            carga: 0,
            btns:[
                { texto:'Restablecer', color:'bg-general', accion:'restablecer'},
                { texto:'Guardar', color:'bg-grr-red', accion:'guardar'}
            ]
        }
    },
    computed: {
        getDireccionCon(){
            const { ciudad = '' } = this.ciudades.find(x => x.id === this.model.idm_ciudad) || {};
            const { nombre: pais = '' } = this.paises.find(x => x.id === this.model.idm_pais) || {};
            return [this.model.direccion, ciudad, pais].filter(el => el).join(',')
        },
    },
    methods: {
        async toggle(model){
            this.$refs.editarDatos.toggle()
            this.model = {...model}
            await this.consultarPaises()
            this.coordenadas.lat = +model.latitud
            this.coordenadas.lng = +model.longitud
            await  this.consultarEstados()
            this.model.idm_estado = model.idm_estado
            this.model.idm_ciudad = model.idm_ciudad

            await this.consultarCiudades()
            this.model.idm_ciudad = model.idm_ciudad
            this.$refs.mapaPeq.setPosition()

            if (model.logo !== null){
                this.$refs.cropLogo.set_image(`${model.logo_firmado}`)
            } else {
                this.$refs.cropLogo.instanciaCrop.remove()
            }
            this.fileList = []
            if (model.nit_soporte_firmado){
                this.fileList.push({
                    name: 'Ajunto nit',
                    url: model.nit_soporte_firmado
                })
            }
        },
        acciones(click){
            if (click == 'restablecer'){
                this.cargarDatos()
            }else{
                this.updateDatos()
            }
        },
        abrirModalMapa(){
            this.$refs.modalMapaGoogle.toggle()
        },
        async cargarDatos(){
            try {
                const { data } = await DatosTienda.getDatosTienda(this.$usuario.tienda.id)
                this.model = {...data.data}
                await this.consultarPaises()

                this.coordenadas.lat = +this.model.latitud
                this.coordenadas.lng = +this.model.longitud
                this.$refs.mapaPeq.setPosition()
                await  this.consultarEstados()
                this.model.idm_estado = data.data.idm_estado
                this.model.idm_ciudad = data.data.idm_ciudad

                await this.consultarCiudades()
                this.model.idm_ciudad = data.data.idm_ciudad
                this.model.tienda = data.data.nombre !== null ? true : false

                if (this.model.logo !== null){
                    this.$refs.cropLogo.set_image(`${this.model.logo_firmado}`)
                } else {
                    this.$refs.cropLogo.instanciaCrop.remove()
                }
                this.fileList = []
                if (this.model.nit_soporte_firmado){
                    this.fileList.push({
                        name: 'Ajunto nit',
                        url: this.model.nit_soporte_firmado
                    })
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        handleRemove(file, fileList){
            this.archivo = []
            this.fileList = []
            this.$refs.adjuntos.clearFiles()
        },
        async agregar_archivos(file, fileList){
            let nombreMayor100Carecteres = file.name.length > 100
            let tipo = null
            let tamano = null
            if (file.raw.type === 'application/pdf'){
                tipo = 1
                tamano = 20
            } else if (file.raw.type.split('/').slice(0, 1).pop() === 'image'){
                tipo = 2
                tamano = 1000
            } else if (file.raw.type === 'video/mp4'){
                tipo = 3
                tamano = 20
            } else {
                tipo = 4
                tamano = 25
            }

            const limite = file.size / 1024 / 1024 <= tamano
            if (!limite){
                fileList.splice(fileList.indexOf(file), 1)
                await this.notificacion('No válido', 'El archivo no cumple los requisitos', 'warning')
                return
            }

            if (tipo == 3 || tipo == 4){
                fileList.splice(fileList.indexOf(file), 1)
                await this.notificacion('No válido', 'El archivo no cumple con la extensión requerida(pdf ó imagenes)', 'warning')
                return
            }

            if (nombreMayor100Carecteres){
                fileList.splice(fileList.indexOf(file), 1)
                await this.notificacion('Error', `¡El nombre del archivo ${file.name} excede los 100 caracteres!`, 'error')
                return
            }

            this.archivo.push(file.raw)
        },
        async updateDatos(){
            try {

                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                const archivo = this.$refs.cropLogo.instanciaCrop.dataBase64.output
                this.model.logo = archivo.image
                this.model.logo_mini = null
                this.model.tipo = 1
                this.model.id_tienda = this.$usuario.tienda.id

                if (this.archivo.length){
                    this.model.nit_soporte = this.archivo[0]
                }else{
                    this.model.nit_soporte = null
                }

                let formData = this.crear_formData(this.model)

                const {data} = await DatosTienda.updateDatos(formData)
                this.notificacion('Mensaje', 'Editado exitosamente', 'success')
                this.$emit('actualizar')
                if (this.tienda){
                    this.$refs.adjuntos.clearFiles()
                    this.archivo = []
                }
                this.$refs.editarDatos.toggle()
            } catch (e){
                this.error_catch(e)
            }
        },
        async consultarPaises(){
            try {
                const {data} = await Localizaciones.consultarPaises(this.$usuario.proyecto.id)
                this.paises = data.data
            } catch (e){
                this.error_catch(e)
            }
        },
        async consultarEstados(){
            try {
                this.model.idm_estado = null
                this.model.idm_ciudad = null
                this.estados = []
                this.ciudades = []
                const {data} = await Localizaciones.consultarEstados({
                    proyecto: this.$usuario.proyecto.id,
                    pais: this.model.idm_pais
                })

                this.estados = data.data
            } catch (e){
                this.error_catch(e)
            }
        },
        async consultarCiudades(){
            try {
                this.model.idm_ciudad = null
                this.ciudades = []
                const {data} = await Localizaciones.consultarCiudades({
                    proyecto: this.$usuario.proyecto.id,
                    estado: this.model.idm_estado
                })
                this.model.idm_ciudad = null
                this.ciudades = data.data
            } catch (e){
                this.error_catch(e)
            }
        },
        actualizarCoordenadasCiudad({ latitud, longitud }){
            console.log({ latitud, longitud });
            this.model.latitud = latitud
            this.model.longitud = longitud
            this.coordenadas.lat = +this.model.latitud
            this.coordenadas.lng = +this.model.longitud
            this.$refs.mapaPeq.setPosition()
        },
        actualizarCoordenadas({ latitud, longitud }){
            this.model.latitud = latitud
            this.model.longitud = longitud
        },
        buscarDireccion(){
            this.$refs.mapaPeq.accionBuscar();
        }
    }
}
</script>
